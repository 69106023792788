import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import {getAccumulatedDeductions} from '../../../helper/Api';
import DataTable from 'react-data-table-component';
import {AddCommasToAmount} from "../../../helper/Utils";
import {useLocation, useParams} from "react-router-dom";
import { Link } from 'react-router-dom';
import {AiOutlineEye} from "react-icons/ai";

const AccumulatedDeductionList = ({fromDate, toDate, dateColumn, searchStr}) => {
    // debugger
    const {authData} = useSelector((state) => state.authData);
    // const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    let {state} = useLocation();
    const {reportType} = useParams();
    const [data, setData] = useState(state && state.data ? state.data : []);

    const fetchChallans = async () => {
        try {
            const report = (reportType !== 'Accumulated Deduction') ? 'utilized-budget' : '';
            const data = await getAccumulatedDeductions({
                from_date: fromDate,
                to_date: toDate, 
                report,
                dateColumn: dateColumn,
                search_str: searchStr
            });
            if (data.data.status) {
                setData(data.data.list);
            }
        } catch (error) {
            setData([]);
        }
    }

    useEffect(() => {
        fetchChallans();
    }, [])

    useEffect(() => {
        fetchChallans();
    }, [fromDate, toDate, dateColumn, searchStr])


    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    let challanColumns = [{
        name: 'TDS IT Deduction Amount',
        selector: (row) => Number(row?.tds_it_amount),
        sortable: true,
        width: "150px",
        right: false
    },
    {
        name: 'TDS GST Deduction',
        selector: (row) => Number(row?.s_gst_amount) + Number(row?.c_gst_amount) + Number(row?.i_gst_amount),
        sortable: true,
        width: "150px",
        right: false
    },
    {
        name: 'GIS Deduction',
        selector: (row) => Number(row?.gis),
        sortable: true,
        width: "150px",
        right: false
    },
    {
        name: 'NPS Deduction',
        selector: (row) => Number(row?.nps),
        sortable: true,
        width: "150px",
        right: false
    },
    {
        name: 'Other Deduction',
        selector: (row) => Number(row?.other_deduction),
        sortable: true,
        width: "150px",
        right: false
    },]

    if (reportType !== 'Accumulated Deduction') {
        challanColumns = [
            // {
            //     name: 'Transaction\n' +
            //         'Date',
            //     selector: (row) => row.amount,
            //     sortable: true,
            //     width: "150px",
            //     right: false
            // },
            {
                name: 'Actions (L2)',
                selector: (row) => {
                    let status = row.payment_status;
                    return !row.isFooter ? (
                        <b>{status == 1 ? 'COMPLETED' : 'PENDING'}</b>
                    ) : <b>Total</b>;
                },
                // sortable: true,
                width: "200px"
            },
            {
                name: 'Actions (L3)',
                selector: (row) => {
                    let status = row.l3_payment_status;
                    return !row.isFooter ? (
                        <b>{status == 1 ? 'COMPLETED' : 'PENDING'}</b>
                    ) : ''
                },
                // sortable: true,
                width: "200px"
            },
        ];
    }

    const columns = [
        {
            name: 'View',
            selector: (row) => <Link to={`/view-invoice/${row.id}`}><span><AiOutlineEye/></span></Link>,
            sortable: true,
            wrap: true,
            width: "100px"
        },
        {
            name: 'Beneficiary\n' +
                'Name',
            selector: (row) => row.company_name,
            sortable: true,
            wrap: true,
            width: "200px"
        },
        {
            name: 'Invoice No',
            selector: (row) => row.invoice_no,
            wrap: true,
            sortable: true,
            width: "200px",
        },
        {
            name: 'Invoice Date',
            selector: (row) => row.invoice_date,
            wrap: true,
            sortable: true,
            width: "200px",

        },
        {
            name: 'Payment\n' +
                'Type',
            selector: (row) =>
                <b>{row.payment_type === '1' ? "FULL PAYMENT" : row.payment_type === '2' ? 'PART PAYMENT' : ''}</b>,
            // sortable: true,
            width: "250px",
            wrap: true,
        },
        {
            name: 'INVOICE\n' +
                'AMOUNT',
            selector: (row) => Number(row?.payable_amount),
            sortable: true,
            width: "150px",
            right: false
        },
        ...challanColumns,
        {
            name: 'Voucher No',
            selector: (row) => row.voucher_no,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Voucher\n' +
                'Date',
            selector: (row) => row.voucher_date,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Taxable\n' +
                'Amount',
            selector: (row) => Number(row?.taxable_amount),
            sortable: true,
            width: "150px",
            right: false
        },
         {
            name: 'INVOICE\n' +
                'Amount\n' +
                'Sanctioned',
            selector: (row) => Number(row?.sanction_amount),
            sortable: true,
            width: "150px",
            right: false
        }, 
        {
            name: 'Payment Status (L2)',
            selector: (row) => {
                let status = row.payment_status;
                return !row.isFooter ? (
                    <b>{status == 1 ? 'COMPLETED' : 'PENDING'}</b>
                ) : <b>Total</b>;
            },
            width: "200px"
        },
        {
            name: 'Payment Status (L3)',
            selector: (row) => {
                let status = row.l3_payment_status;
                return !row.isFooter ? (
                    <b>{status == 1 ? 'COMPLETED' : 'PENDING'}</b>
                ) : ''
            },
            width: "200px"
        },
        
        // {
        //     name: 'Details',
        //     width: "200px",
        //     selector: (row) => row?.invoice ?
        //         <a href={row?.invoice} target="_blank" rel="noreferrer">Attachment</a> : '',
        //     sortable: false,
        // },
    ];

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        // setCurrentPage(1); // Reset to the first page when rows per page changes
    };

    const calculateTotalAmount = (propertyName) => {
        let total = 0;
        data.forEach((row) => {
            const depositAmount = Number(parseFloat(row[propertyName]));
            if (!isNaN(depositAmount)) {
                total += depositAmount;
            }
        });
        return total.toFixed(2);
    };

    const totalInvoiceAmount = calculateTotalAmount('tds_it_amount');

    const footerRow = {
        isFooter: true,
        selectableRowsSingle: false,
        invoice_no: <b>Total</b>,
        tds_it_amount: <b>{AddCommasToAmount(totalInvoiceAmount)}</b>,
    };

    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                // selectableRows={authData.user.user_type === 'l1'}
                // onSelectedRowsChange={handleSelectedChange}
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                /*paginationComponent={() => (
                    <CustomPagination
                        rowsPerPage={rowsPerPage}
                        rowCount={data.length}
                        currentPage={currentPage}
                        onChangePage={handleChangePage}
                    />
                )}*/
            />
        </div>
    )
}

export default AccumulatedDeductionList
