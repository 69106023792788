import React, { useState } from 'react'
import {useSelector} from 'react-redux';
import Tools from "../../components/Tools/Tools";
import TDSReportList from "../../components/Reports/TDSReportList";
import ManageDisbursmentHeader from "../../components/Disbursment/ManageDisbursmentHeader";


const TDSitReport = () => {
    const {authData} = useSelector((state) => state.authData);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchStr, setToSearchStr] = useState('');
    const [dateColumn, setDateColumn] = useState('voucher_date');


    const handleDateChange = (start, end, dateColumn, searchStr) => {
        setFromDate(start);
        setToDate(end);
        setToSearchStr(searchStr);
        setDateColumn(dateColumn);
    };

    return (
        <div>
            <Tools/>
            {/* <div className='addNewScheme'>
                <div className="add_new_user">
                    <h4>TDS-IT</h4>
                </div>
            </div> */}
            <ManageDisbursmentHeader onDateChange={handleDateChange} dateColumn={dateColumn} searchStr={searchStr} startDate={fromDate} endDate={toDate} type="TDS IT REPORT" />
            <div className='scheme p-3'>
                <TDSReportList dateColumn={dateColumn} searchStr={searchStr} fromDate={fromDate} toDate={toDate}/>
            </div>
        </div>
    )
}

export default TDSitReport
