import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

export default function ApproveReimbursementPreview({setShowPreview, showPreview, formData, handleSubmit, fromSchemeDetails, fy_list}) {
    const handleClose = () => setShowPreview(false);
    const get_financial_year = (id) => {
        if (id) {
            const filter = fy_list.filter((fy) => fy.id == id);
            if (filter.length > 0) {
                return filter[0];
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    return (
        <div>
            <Modal
             size="lg"
            show={showPreview} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Beneficiary Reimbursement Approval Preview : </Modal.Title>
                </Modal.Header>
                <Modal.Body className='previewModalBody'>
                <h6>Basic info:</h6>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Reimbursement Type</th>
                                <th>Scheme Code</th>
                                <th>Financial Year</th>
                                <th>Subhead</th>
                                <th>Subhead Balance</th>
                                <th>Reimbursement Order No.</th>
                                <th>Reimbursement Order Date</th>
                                <th>Reimbursement Order Value</th>
                                <th>Reimbursement Order Doc</th>
                                <th>Reimbursement Supporting Doc</th>
                            </tr>
                        </thead>
                        <tbody> 
                            <tr>
                                <td>{formData.reimbursement_type}</td>
                                <td>{fromSchemeDetails.scheme_name}</td>
                                <td>{get_financial_year(formData.financial_year)?get_financial_year(formData.financial_year).start_year+"-"+get_financial_year(formData.financial_year).end_year:""}</td>
                                <td>{fromSchemeDetails.subhead_name}</td>
                                <td>{fromSchemeDetails.subhead_balance}</td>
                                <td>{formData.reimbursement_order_no}</td>
                                <td>{formData.reimbursement_order_date}</td>
                                <td>{formData.reimbursement_order_value}</td>
                                <td><a className="mt-2 mx-2" href={fromSchemeDetails.reimbursementOrderDoc} target='_blank'>Attachment</a></td>
                                <td><a className="mt-2 mx-2" href={fromSchemeDetails.reimbursementSupportDoc} target='_blank'>Attachment</a></td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Change
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        SUBMIT
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}