import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import {getChallans} from '../../../helper/Api';
import DataTable from 'react-data-table-component';

const ChallanList = () => {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);

    const fetchChallans = async () => {
        try {
            const data = await getChallans();
            if (data.data.status) {
                setData(data.data.result);
            }
        } catch (error) {
            setData([]);
        }
    }

    useEffect(() => {
        fetchChallans();
    }, [])

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };
    const columns = [
        {
            name: 'Bank Account No',
            selector: (row) => row.scheme_name,
            wrap: true,
            sortable: true,
            width: "250px",
        },
        {
            name: 'SNA Agency Name',
            selector: (row) => row.sna_agency_name,
            wrap: true,
            sortable: true,
            width: "250px",

        },
        {
            name: 'Head Code',
            selector: (row) => row.h_code,
            sortable: true,
            width: "250px",
            wrap: true

        },
        {
            name: 'Challan No.',
            selector: (row) => row.challan_no,
            sortable: true,
            width: "200px",
            right: false
        },
        {
            name: 'Date',
            selector: (row) =>row.challan_date,
            sortable: true,
            width: "150px",
            right: false
        }, {
            name: 'Amount',
            selector: (row) => row.amount,
            sortable: true,
            width: "200px",
            right: false
        }
    ];

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
    };

    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}

export default ChallanList
