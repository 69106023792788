import React from 'react'
import Tools from '../../components/Tools/Tools'
import EditSchemeForm from '../../components/BudgetAllocation/Schemes/EditSchemeForm'


const EditScheme = () => {
  return (
    <div>
        <Tools/>
        <div className='scheme'>
            <EditSchemeForm/>
        </div>
    </div>
  )
}

export default EditScheme
