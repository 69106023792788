import React, {useState} from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Tools from "../../../components/Tools/Tools";
import ManageDisbursmentHeader from "../../../components/Disbursment/ManageDisbursmentHeader";
import AccumulatedDeductionList from "../../../components/Reconciliation/Accumulated_deduction/AccumulatedDeduction";


const AccumulatedDeduction = () => {
    const {authData}=useSelector((state)=>state.authData);
    // const [selectedTDS,setSelectednvoice]=useState([])
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const {reportType} = useParams();
    const [dateColumn, setDateColumn] = useState('voucher_date');
    const [searchStr, setToSearchStr] = useState('');


    const handleDateChange = (start, end, dateColumn, searchStr) => {
        setFromDate(start);
        setToDate(end);
        setDateColumn(dateColumn);
        setToSearchStr(searchStr);
    };

    return (
        <div>
            <Tools/>
            <div className='addNewScheme' >
                <div className="add_new_user">
                    <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} dateColumnType={dateColumn} type={reportType} />
                </div>
            </div>
            <div className='scheme p-3'>
                <AccumulatedDeductionList fromDate={fromDate} toDate={toDate} dateColumn={dateColumn} searchStr={searchStr}/>
            </div>
        </div>
    )
}

export default AccumulatedDeduction
