import React, {useEffect, useState, useRef} from 'react';
import DataTable from 'react-data-table-component';
import {useSelector} from 'react-redux';
import { changeBeneficiaryStatus, changeBeneficiaryAccountStatus } from '../../../helper/Api';
import BankAccountModal from './BankAccountModal';
import {toast} from 'react-toastify';

const BankAccountList = ({data}) => {
    const {authData} = useSelector((state) => state.authData);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState(data);
    const modal = useRef();
    const activeActionId = useRef(null);
    const [modalContent, setModalContent] = useState({
        beneficiaryName: "",
        accountNo: "",
        bankName: ""
    });
    const [formData, setFromData] = useState({
        id: "",
        l2_status: "",
        l3_status: ""
    })
    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'Project Name',
            selector: (row) => row.project_name,
            sortable: true,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Beneficiary Name',
            selector: (row) => row.beneficiary_name,
            sortable: true,
            width: "200px",
            right: false
        },
        {
            name: 'Bank Name',
            selector: (row) => row.bank_name,
            sortable: true,
            width: "200px",
            right: false
        },
        {
            name: 'Branch Name',
            selector: (row) => row.branch_name,
            sortable: true,
            width: "250px",
            right: false
        },
        {
            name: 'Account No',
            selector: (row) => row.account_no,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'IFSC Code',
            selector: (row) => row.ifsc_code,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Status',
            selector: (row) => <b>{row.status == 1 ? 'Active' : 'Inactive'}</b>,
            // sortable: true,
            width: "100px",
            right: false
        },
        {
            name: 'L2 Status',
            selector: (row) => <>{row.l2_status !== null ? row.l2_status == 1 ? 'Approved' : 'Rejected' : 
            authData.user.user_type == 'l2' ? <button type="button" 
            class="btn btn-primary" onClick={() => handleAccountApproval({
                beneficiaryName: row.beneficiary_name,
                bankName: row.bank_name,
                accountNo: row.account_no,
                projectName: row.project_name,
                id: row.id
            })}>Process</button> : 'Pending'}</>,
            width: "150px",
            right: false
        },
        {
            name: 'L3 Status',
            selector: (row) => <>{row.l3_status !== null ? row.l3_status == 1 ? 'Approved' : 'Rejected' : 
                authData.user.user_type == 'l3' ? <button type="button" 
                class="btn btn-primary" onClick={() => handleAccountApproval({
                    beneficiaryName: row.beneficiary_name,
                    bankName: row.bank_name,
                    accountNo: row.account_no,
                    projectName: row.project_name,
                    id: row.id
                })}>Process</button> : 'Pending'}</>,
            width: "150px",
            right: false
        },
        {
            name: 'Action',
            width: "150px",
            selector: (row) => <button 
            type="button" 
            class="btn btn-primary"
            onClick={() => handleChangeStatus(row.status, row.id)}
            >
                {row.status == 1 ? 'Inactive' : 'Active'}
                </button>,
            // sortable: false,
        },
    ];
    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };

    const handleChangeStatus = async (currentStatus, accountId) => {
        try {
            const res = await changeBeneficiaryStatus(accountId, currentStatus);
            if (res.data) {
                setTableData(res.data.result[0].beneficiary_accounts)
            }
        }catch(e) {

        }
    }

    const handleAccountApproval = (dataObj) => {
        setModalContent(dataObj);
        activeActionId.current = dataObj.id;
        modal.current = true;
        return ;
    }

    const handleApprove = async (action) => {
        const post_data = new FormData();
        post_data.append('id', activeActionId.current);
        if(authData.user.user_type == 'l2') {
            post_data.append('l2_status', action);
        }
        if(authData.user.user_type == 'l3') {
            post_data.append('l3_status', action);
        }
        const response = await changeBeneficiaryAccountStatus(post_data);
        let addAccountData = response.data;
        if (addAccountData.status) {
            modal.current = false;
            setTableData(addAccountData.result[0].beneficiary_accounts)
        }else {
            toast.error(addAccountData.message, {
                position: toast.POSITION.TOP_LEFT
            });
        }
    }

    return (
        <div>
            <BankAccountModal ref={modal} onApprove={handleApprove}>
                <p>Beneficiary Name: {modalContent.beneficiaryName}</p>
                <p>Bank Name: {modalContent.bankName}</p>
                <p>Account No: {modalContent.accountNo}</p>
            </BankAccountModal>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={tableData}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={tableData.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                /*paginationComponent={() => (
                    <CustomPagination
                        rowsPerPage={rowsPerPage}
                        rowCount={data.length}
                        currentPage={currentPage}
                        onChangePage={handleChangePage}
                    />
                )}*/
            />
        </div>
    )
}

export default BankAccountList