import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import './DashboardBox.css'
import GridBox from './GridBox'
import {PiAlarmBold,PiNotepadFill} from 'react-icons/pi'
import {BiBookmarkAltMinus} from 'react-icons/bi'
// import {IoCalendarClearOutline} from 'react-icons/io5'
import {CgCalendar} from 'react-icons/cg'
// import { get_dashboard_data } from '../../helper/Api'
import {AddCommasToAmount} from '../../helper/Utils'
import InterestFromBankBox from './InterestFromBankBox'
import {Col, Container, Row} from 'react-bootstrap'


const DashboardBox = ({data, showPopupFun, listReload}) => {
  const navigate = useNavigate();
  const navigateToScheme = (url) => {
    navigate(url);
  };
// console.log('data', data);
  return (
    <>
    <div className="add_new_user">
      <Container fluid>
        <Row>
          <Col sm={4}>
          <div className='dashboardStatCard'>
            <h3>Scheme Budget</h3>
            <h6>Provisioned Budget</h6>
            <h5>{AddCommasToAmount(data.total_provisional_budget.provisional_budget)}</h5>
            <h6>Released Budget</h6>
            <h5>{AddCommasToAmount(Number(data.total_provisional_budget.released_budget).toFixed(2))}</h5>
            <h6>Prev. Year Balance</h6>
            <h5>{AddCommasToAmount(Number(data.prevYearBalance).toFixed(2))}</h5>
            <hr/>
            <h4>Total Budget</h4>
            <h4>{AddCommasToAmount((Number(data.prevYearBalance) + Number(data.total_provisional_budget.released_budget)).toFixed(2))}</h4>
          </div>
          </Col>
          <Col sm={4}>
            <div className='dashboardStatCard' style={
              {backgroundColor: '#FFE7E5'}
            }>
              <h3>Utilized Budget</h3>
              <h6>From FY 2024-25</h6>
              <h5>{AddCommasToAmount(Number(data.currenyFyUtilization).toFixed(2))}</h5>
                <h6> From Prev. Year Balance</h6>
                <h5>{AddCommasToAmount(Number(data.prevFyUtilization).toFixed(2))}</h5>
              <hr/>
              <h4>FY 2024-25 Utilized</h4>
              <h4>{AddCommasToAmount(data.utilised_budget)}</h4>
            </div>
          </Col>
          <Col sm={4}>
            <div className='dashboardStatCard'>
              <h3>Scheme Balance</h3>
              <h6>From Current FY</h6>
              <h5>{AddCommasToAmount(Number(data.total_provisional_budget.released_budget - data.currenyFyUtilization).toFixed(2))}</h5>
              <h6>From Prev. Year Balance</h6>
              <h5>{AddCommasToAmount(Number(data.prevYearBalance - data.prevFyUtilization).toFixed(2))}</h5>
              <hr />
              <h4>Total Balance</h4>
              <h4>{AddCommasToAmount(((Number(data.prevYearBalance) + Number(data.total_provisional_budget.released_budget)-Number(data.currenyFyUtilization + data.prevFyUtilization))).toFixed(2))}</h4>
            </div>
          </Col>
          <Col sm={4}>
            <div className='dashboardStatCard' style={
              { backgroundColor: '#FFE7E5' }
            }>
              <h3>Approvals</h3>
              <h6><span>{data.pending_invoices_count}</span> Invoices for approval</h6>
              <h5>{AddCommasToAmount(data.sanctioned_amount)}</h5>
              <h6>{data.payable_expenses_invoice_count} Invoices for Payment</h6>
              <h5>{AddCommasToAmount(data.payable_expanses)}</h5>
              <h6>0 Reimbursment for approval</h6>
              <h4>{data?.reimbursement_details[0]?.under_approval > 0 ? data?.reimbursement_details[0]?.under_approval : 0}</h4>
              <h6>0 Reimbursment for payment</h6>
              <h4>{data?.reimbursement_details[1]?.payment_approval > 0 ? data?.reimbursement_details[1]?.payment_approval : 0}</h4>
            </div>
          </Col>
          <Col sm={4}>
            <div className='dashboardStatCard'>
              <h3>Utilized Budget</h3>
              <p>Current Month</p>
              <h6>From Current FY</h6>
              <h5>{AddCommasToAmount(Number(data.currentMonthUtilize_cfy).toFixed(2))}</h5>
              <h6>From Prev. Year Balance</h6>
              <h5>{AddCommasToAmount(Number(data.currentMonthUtilize_pfy).toFixed(2))}</h5>
              <hr />
              <h4>Current Month Utilized</h4>
              <h4>{AddCommasToAmount(Number(data.currentMonthUtilize_cfy + data.currentMonthUtilize_pfy).toFixed(2))}</h4>
            </div>
          </Col>
          <Col sm={4}>
            <div className='dashboardStatCard' style={
              {backgroundColor: '#FFE7E5'}
            }>
              <h3>Accumulated Deduction</h3>
              <h5>{AddCommasToAmount(data?.accumulated || 0)}</h5>
            </div>
          </Col>
        </Row>
      </Container>
      </div>
    <div className="dashboardBox">
        
        {/* <GridBox caption="" onClick={() => navigateToScheme('/budgets')} icon={<PiAlarmBold/>} title={"Provisioned Budget"} amount= /> */}
        {/* <GridBox caption="" onClick={() => navigateToScheme('/budgets')} icon={<PiAlarmBold/>} title={"Released Budget"} amount= /> */}
        {/* <GridBox caption="" onClick={() => navigateToScheme('/reports/Utilized Budget')} icon={<PiNotepadFill/>} title={"Utilized Budget"} amount={AddCommasToAmount(data.utilised_budget)} box_bg={'#DAFBEE'} icon_bg="#5CBA96"/> */}
        {/* <GridBox caption="" onClick={() => navigateToScheme('/fund-invoice-list?active=invoice-status')} icon={<BiBookmarkAltMinus/>} title={"Pending for Approval"}  amount={AddCommasToAmount(data.sanctioned_amount)} box_bg={'#FFE7E5'} icon_bg="#EC8D87" subtitle={"No Of Invoices"} subtitleValue={data.pending_invoices_count}/>
        <GridBox caption="" onClick={() => navigateToScheme('/fund-invoice-list?active=payment-status')} icon={<BiBookmarkAltMinus/>} title={"Pending for Payments"} amount={AddCommasToAmount(data.payable_expanses)} box_bg={'#FFE7E5'} icon_bg="#EC8D87" subtitle={"No Of Invoices"} subtitleValue={data.payable_expenses_invoice_count}/> */}
        {/* <GridBox caption="" onClick={() => navigateToScheme('/schemes')} icon={<CgCalendar/>} title={"Balance Budget"} amount={AddCommasToAmount(data.balance)} box_bg={'#FFF4DE'} icon_bg="#ECBA5D"/> */}
        {/* <GridBox caption="" onClick={() => navigateToScheme('/reports/Accumulated Deduction')} icon={<CgCalendar/>} title={"Accumulated Deductions"} amount={AddCommasToAmount(data?.accumulated || 0)} box_bg={'#FFF4DE'} icon_bg="#ECBA5D"/> */}
        <InterestFromBankBox handleEditAccount={showPopupFun} reloadList={listReload}/>
    </div>
    </>
  )
}

export default DashboardBox
