import React from 'react';
import Tools from "../../components/Tools/Tools";
import AddBeneficiaryReimbursement from '../../components/BeneficiaryReimbursement/AddBeneficiaryReimbursement';

export default function AddBeneficiaryReimbursementPage() {
    return (
        <div>
          <Tools/>
          <AddBeneficiaryReimbursement/>
        </div>
      )
}