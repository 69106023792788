import {useImperativeHandle, useRef, forwardRef} from 'react'
import { createPortal } from "react-dom";
import Modal from 'react-bootstrap/Modal';
export default forwardRef(function BankAccountModal({children, onApprove, onReject}, ref) {
    const showModal = ref.current || false;
    const handleClose = () => {
        ref.current = false;
    }
    return <Modal show={showModal}>
        <Modal.Body className='previewModalBody'>
            {children}
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-danger w-30 mb-2" onClick={() => onApprove(0)}>Reject</button>
            <button type="button" className="btn btn-primary w-30 mb-2" onClick={() => onApprove(1)}>Approve</button>
            <button type="button" className='btn btn-secondary w-30 mb-2' onClick={handleClose}>Close</button>
        </Modal.Footer>
    </Modal>;
});