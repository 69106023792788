import React from 'react';
import Tools from "../../components/Tools/Tools";
import AddDepository from '../../components/Depository/AddDepository';

const AddDepositoryPage = () => {
  return (
    <div>
      <Tools/>
      <AddDepository />
    </div>
  )
}

export default AddDepositoryPage
