import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import DataTable from 'react-data-table-component';
import {useLocation} from "react-router-dom";
import {getDepositoryReports} from "../../helper/Api";
import {AddCommasToAmount} from "../../helper/Utils";
import {AiOutlineEye} from 'react-icons/ai';
import {BiSolidEdit} from 'react-icons/bi';
import {Link} from 'react-router-dom';

const DepositoryList = (filterDate) => {
    const {authData} = useSelector((state) => state.authData);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    let {state} = useLocation();
    const [data, setData] = useState(state && state.data ? state.data : []);
    const [vendor, setVendorData] = useState('');

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');


    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'View Document',
            width: "200px",
            selector: (row) => row?.document_url ?
                <a href={row?.document_url} target="_blank" rel="noreferrer"><AiOutlineEye/></a> : '',
            center: true
            // sortable: false,
        },
        {
            name: 'Beneficiary\n' +
                'Name',
            selector: (row) => row.company_name,
            sortable: true,
            wrap: true,
            width: "200px"
        },
        {
            name: 'Type',
            selector: (row) => row.depository_type,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Description',
            selector: (row) => row.description,
            sortable: true,
            width: "350px",
            right: false
        },

        {
            name: 'Approval Date',
            selector: (row) => (row.approval_date == '0000-00-00') ? '' : row.approval_date,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Valid From',
            selector: (row) => (row.valid_from == '0000-00-00') ? '' : row.valid_from,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Valid To',
            selector: (row) => (row.valid_to == '0000-00-00') ? '' : row.valid_to,
            // sortable: true,
            width: "150px",
            right: false
        },
        
        {
            name: 'Edit / Update',
            width: "200px",
            selector: (row) => {
                return <>
                    {
                        (authData.user.user_type === 'l1') ? <Link to={`/add-depository/${row.id}`}><BiSolidEdit/></Link> : ''
                    }
                </>
            },
            center: true,
        },
    ];

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };
    // const paginatedData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
    // console.log('data', data, paginatedData);

    // let updatedData = [];

    // if(paginatedData.length) {
    //     updatedData = [...paginatedData];
    // } else {
    //     updatedData = [];
    // }


    // const handleInput = (e) => {
    //     const name = e.target.name;
    //     let value = e.target.value;
    //     if (name === 'fromDate') {
    //         setFromDate(value);
    //     } else {
    //         setToDate(value);
    //     }
    // }

    const handleFilterClick = async () => {
        // await fetchBeneficiary(schemeId)
        setData([]);
        await fetchChallans()
    };
    const clearFilter = async () => {
        setFromDate(''); // Clear fromDate
        setToDate('');
        await fetchChallans()
        // await fetchBeneficiary(schemeId)
    };

    const fetchChallans = async () => {
        try {
            const resp = await getDepositoryReports({
                from_date: filterDate?.fromDate || '', 
                to_date: filterDate?.toDate || '',
                search_str: filterDate?.searchStr || '',
                dateColumn: filterDate?.dateColumn || ''
            });
            if (resp.data.status) {
                setData(resp.data.list);
            }
        } catch (error) {
            setData([]);
        }
    }
    // console.log('fdate', filterDate.fromDate);
    useEffect(() => {
        fetchChallans();
    }, [filterDate.fromDate, filterDate.toDate, filterDate.dateColumn, filterDate.searchStr])



    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                /*paginationComponent={() => (
                    <CustomPagination
                        rowsPerPage={rowsPerPage}
                        rowCount={data.length}
                        currentPage={currentPage}
                        onChangePage={handleChangePage}
                    />
                )}*/
            />
        </div>
    )
}

export default DepositoryList
